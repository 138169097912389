import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly3C2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/class-assembly/3C/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/class-assembly/3C/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/class-assembly/3C/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/class-assembly/3C/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/class-assembly/3C/5.webp`;



    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 2,
        },

        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 2,
        },

        {
            src: p5,
            source: p5,
            rows: 1.5,
            cols: 2,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Sharing is Magic
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 3 C Date: : 23 November 2024

                                </Typography>
                                {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
  Smiles are free, but they are worth a lot.
<br/>
        </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The students of Class 3C presented their assembly on the topic, ‘Sharing is Magic’ on Saturday, 23 November 2024.
                                    <br></br>
                                    The speaker demonstrated confidence and clarity while presenting the thought for the day, setting the tone for the rest of the performance. The introduction of the topic was seamless, as the students explained the theme of ‘Sharing is Magic’ in a way that was both simple and profound, ensuring that the audience understood the message from the outset.
                                    <br></br>
                                    The students portrayed a variety of everyday situations, such as sharing toys, helping others in need, and cooperating in group activities. The expressions and their body language, captured the joy and fulfilment that comes from sharing, as well as the positive emotional effects it has on both the giver and the receiver. The mime demonstrated a clear understanding of how acts of kindness and collaboration foster a sense of community and well-being.
                                    <br></br>
                                    The conclusion of the assembly was equally impactful, as the speaker showcased her confidence in wrapping up the performance with a strong, message.
                                    <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "Sharing is the magic that makes the world a better place."
                                <br></br>

                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <ClassAssembly2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>
                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ClassAssembly3C2024;